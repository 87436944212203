<script lang="ts">
import Markdown from ".//CHANGELOG.md";

export default {
  components: {
    Markdown,
  },
}
</script>

<template>
  <Markdown />
</template>
